import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for macOS",
  "path": "/Software/macOS/InstarVision/Advanced/Log/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "View your Software Log to spot potential Problems",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='InstarVision v3 for macOS' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/macOS/InstarVision/Advanced/Log/' locationFR='/fr/Software/macOS/InstarVision/Advanced/Log/' crumbLabel="Log" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAe0lEQVQI143HsQrCMBSF4fsq+nwObordHZrrFHERJ/sqLq4RWnF3cGxSQwzlF1pn6YGPwy/0CaKHGCB18G7HJjN11lrWmwJyRIwLqPPorUPvGW1Gpvlg6vRXWSd2j55iq6yWC0rnETm9kOOT4SuPnH+qqQLz/YXZ4Tr0F9aiwNov85E3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/e4706/Banner_en-InstarVision2-0_Advanced.avif 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/d1af7/Banner_en-InstarVision2-0_Advanced.avif 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/7f308/Banner_en-InstarVision2-0_Advanced.avif 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/e1c99/Banner_en-InstarVision2-0_Advanced.avif 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/76ea5/Banner_en-InstarVision2-0_Advanced.avif 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/f696c/Banner_en-InstarVision2-0_Advanced.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/a0b58/Banner_en-InstarVision2-0_Advanced.webp 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/bc10c/Banner_en-InstarVision2-0_Advanced.webp 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/966d8/Banner_en-InstarVision2-0_Advanced.webp 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/445df/Banner_en-InstarVision2-0_Advanced.webp 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/78de1/Banner_en-InstarVision2-0_Advanced.webp 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/882b9/Banner_en-InstarVision2-0_Advanced.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/81c8e/Banner_en-InstarVision2-0_Advanced.png 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/08a84/Banner_en-InstarVision2-0_Advanced.png 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/b1001/Banner_en-InstarVision2-0_Advanced.png 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/161ec/Banner_en-InstarVision2-0_Advanced.png 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---system-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---system-log",
        "aria-label": "side navigation   system log permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - System Log`}</h2>
    <p>{`View your Software Log to spot potential Problems`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1282ef0b5d09d008cae0882823d79f0b/c929c/en_advanced_log.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADS0lEQVQoz02SbU9TBwCF76/Zhy2ZUwYL0TooSJQynDA3ESgzii4QBMuklJfJKLRVIVBocQITSBZKi4YJyGDTKckwhIyXlLLeS4tuUN4qzOIItrfVZ6H7spOcr09ych5hxu1D+tPPX+svWVre4OnUDFNzbuaktWg7Br20DXjoHJin4+4MmsZHFDVOUNwyxwXTJJXfTeNeWMBht+ORFhG6+n/iprWL7+1D3B19ysUiDaVVtfTcG8XUbOFsYQMZBVbS8ltJybvOh2lXiUnTkqhu5FTFYwqbZ+mz2TCbzQwNDiIoEo6QlZNF5ulMcs+p0RuNDI884MHIMA5HL32OARz3HzI2PsPIr5P8ODbB0M+/8Xm+hpiUXNLP19J5pwub3UGvzYZwMl2FKjUFZZKCRGU8Wt1V1jc2WF314fP5WFtbZXvLz07gb/6fkjId78YcRpWRRXtLM91WM90d7QgnjikpLy2muLiEnLNnqKzQ4vEusbi4iNvtRhTFKHhra5tgMEQwGESWwxgMNahSkygs1VF57zGXbI/oGHmCcDL1MHlfHOdCdiqfJMdSo7vC0rNneL0eJEmKAiVRIhDYIRwOI8sykcgbLJZWcnJz0Vy7jmEpwpfOEGP+MEJtfQt6gxVdpQmt1kBbez/eJS9erzcK26/X48H/4kUUFgzJyJEIt7v6yMwtQVNRx42FlxTO7DC2vINQ32CnuXUYo6mXqm/u0NnzC27RjSSJuFwunE4nKysrhPanhsMQkSESxPXHMuNTz7k/vohmNoBqIkD/81cI6Z+dJu2UmvQzBSR8ms3l6nq2t7bwb26wuelnfX0T3+o6r3b3iMghJrdl7BtvMDW1kZ+jxlBXz25Q5p9QmN291wh6623Kza1omyxcMrZQ021n0ikyPPqQuflZRNfviM4ppPlpxLlprE+cFI3MY7xhxPBVBu3fFiGHQkTCMq/39hDqLNfQ3zJQbanlSkMVZTdr0evLKDifSU35Ocx1ahqrs2k3XaTHrKPVUMEtk5bepst06fP4oflr3r79T6X904Q4xQHijsYQqzjIx0nxJCfEcyIxlmOKD0hWHCLh6CE+in2PI/Hvk6g4yHFlHEkJMSgVB1DEvkNGuhK3KEY127fiXyoeuotOLZ1PAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1282ef0b5d09d008cae0882823d79f0b/e4706/en_advanced_log.avif 230w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/d1af7/en_advanced_log.avif 460w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/7f308/en_advanced_log.avif 920w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/203bb/en_advanced_log.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1282ef0b5d09d008cae0882823d79f0b/a0b58/en_advanced_log.webp 230w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/bc10c/en_advanced_log.webp 460w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/966d8/en_advanced_log.webp 920w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/491b1/en_advanced_log.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1282ef0b5d09d008cae0882823d79f0b/81c8e/en_advanced_log.png 230w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/08a84/en_advanced_log.png 460w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/c0255/en_advanced_log.png 920w", "/en/static/1282ef0b5d09d008cae0882823d79f0b/c929c/en_advanced_log.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1282ef0b5d09d008cae0882823d79f0b/c0255/en_advanced_log.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Log window gives you an overview over the software operation. When did you lose connection to a camera? Are you missing a video recording even though the software registered an alarm event? Choose what kind of information you want to be displayed - System, Recording, Network, Setup or Motion Alert Logs, select the time window that you want to search and click on the Search Button. For advanced users: the log file will be saved to the installation directory of the software. You can use the file for manual backups.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      